import React from "react";
import { Row, Col, Container, Accordion, Card } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import Divider from '../sections/akkadian/Divider';
import imgBanner from "../assets/image/akkadian/faq2.jpg";
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >
        <Helmet>
          <title>Akkadian Health - Frequently Asked Questions</title>
        </Helmet>
        <div className="inner-banner">
          <Container>
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                <div className="px-md-12 text-center mb-11 mb-lg-14">
                  <h1 className="title gr-text-4 mb-9 mb-lg-12">Frequently Asked Questions</h1>
                  <h3 className="gr-text-7 mb-1">
                    Before contacting us, please refer to the information below. 
				  </h3>
				  <p className="gr-text-10 mb-2">
					Our phone lines and administrative staff can be very busy at times throughout the day - if you still can't find the information you need, feel free to drop us a line at 
					<a href="mailto:reception@akkadianhealth.com"> reception@akkadianhealth.com</a>.
                  </p>
                </div>
              </Col>
			</Row>
			<Row className="justify-content-center " style={{backgroundColor:'#3bb6d5', textAlign: 'center'}} >
              <Col xs="12" lg="9" xl="8">
                <div className="banner-fluid-image pt-lg-2" >
                  <img src={imgBanner} alt="" className="w-50" />
				</div>
              </Col>
            </Row>
          </Container>
        </div>






		
        <div className="about-content pt-lg-28 pt-13 pb-13 pb-lg-25">
		

			
          <Container>
            <Row>
              <Col lg="2" className="mb-7 mb-lg-0">
                <div className="pr-xl-10">
                  <h2 className="title gr-text-4 mb-0">
                    Qs
                  </h2>
                </div>
              </Col>
              <Col lg="10">			  
                <div className="pr-xl-13">
				
				
			<Accordion defaultActiveKey="99">
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="99">
						<h3 className="gr-text-8">How does this all work?</h3>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="99">
						<Card.Body>
						<p className="gr-text-10 mb-7 mb-lg-4">
						Akkadian Health operates as a private clinic - <strong>you will need a GP referral to access our services</strong> (psychiatry or psychology). Contact your GP first.</p>
						<p className="gr-text-10 mb-7 mb-lg-4">
						Once we receive and process your referral (within a few business days), you will receive SMS instructions to book your telehealth video appointment.</p>						
						<p className="gr-text-10 mb-7 mb-lg-4">
						You must book with your allocated Akkadian Health clinician via our online booking portal. Choose an appointment time that suits you best.</p>
						<p className="gr-text-10 mb-7 mb-lg-6">
						You'll receive reminders (email and SMS) a day or two before your appointment with details and your unique personal secure telehealth link. At the time of your appointment, simply click on your personal link to join the video session and wait for your clinician.
						</p>
						</Card.Body>
					</Accordion.Collapse>
				</Card>			
			
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="0">
						<h3 className="gr-text-8">What is telehealth?</h3>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="0">
						<Card.Body>
						<p className="gr-text-10 mb-7 mb-lg-4">
						Telehealth works like any modern video conferencing system (e.g., Zoom or Skype) - except our system is secure, private and encrypted. Your appointment with one of our registered clinicians can be conducted in the comfort of your own home.
						</p>
						<p className="gr-text-10 mb-7 mb-lg-4"> 
						All you need is a stable internet connection and a device that handles video (camera) and audio (speaker and microphone). 
						</p>
						<p className="gr-text-10 mb-7 mb-lg-6">
						You'll receive your own unique personal secure telehealth link to join your appointment video session to see your clinician.
						</p>
						</Card.Body>
					</Accordion.Collapse>
				</Card>

				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="1">
						<h3 className="gr-text-8">What types of services do you offer?</h3>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="1">
						<Card.Body>
						  <p className="gr-text-9 mb-5 mb-lg-3">
							<strong>PSYCHIATRY:</strong> Our psychiatrists offer general adult (16 y.o.+) psychiatry for anxiety, depression, stress, trauma, and adult ADHD assessment and management services.
						  </p>
						  <p className="gr-text-12 mb-9 mb-lg-9">
							Our service does not currently include Paediatric or Child & Youth Psychiatrists, Medico-legal reports, Eating Disorder or Addiction specialists. We are no longer conducting Autism Spectrum Disorder (ASD) assessments for adults. We will also consider ASD as part of an overall psychiatric assessment.
						  </p>
						  <p className="gr-text-9 mb-5 mb-lg-3">
							<strong>MENTAL HEALTH THERAPY & ADHD COACHING:</strong> Ongoing therapy is conducted by our psychologists and mental health clinicians. These are allied health services.
						  </p>				  
						  <p className="gr-text-12 mb-9 mb-lg-9">
							The Australian federal govermnment's Better Access initiative currently offers a rebate for access to psychology services (up to 10 sessions within a 12-month period) with a valid <b>GP Mental Health Treatment Plan</b> and associated GP referral. ADHD Coaching is a self-referral service with private billing.
						  </p>	  
						  <p className="gr-text-9 mb-5 mb-lg-3">
							<strong>ADHD TREATMENT:</strong> ADHD treatment services including prescription of ADHD medications are offered through our Adelaide-based Care Centre. These services are only available to SA-based clients.
						  </p>				  
						  <p className="gr-text-12 mb-9 mb-lg-7">
							The <b>Adelaide-based Care Centre</b> manages treatment plans - including prescription of medication for patients diagnosed with ADHD in South Australia. It is conveniently co-located in the Uniting Communities 'UCity' building on Franklin Street in the Adelaide CBD. Contact our Adelaide-based Care Centre today via <a href="mailto:care_sa@akkadianhealth.com">care_sa@akkadianhealth.com</a>.
						  </p>	 						  
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="2">
					  <h3 className="gr-text-8">Do you conduct ADHD assessments?</h3>					
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="2">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-6">
							Yes, we offer adult ADHD assessments (16y.o.+). Referrals to our psychiatry services addressed to 'Akkadian Health' will be allocated to a psychiatrist specialising in ADHD assessments. 
						  </p>
{/* 						  <p className="gr-text-10 mb-7 mb-lg-4">
							ADHD Assessment Care Packages are now available. These 'care packages' include an <strong>'Initial Intake and Screening consultation'</strong> with one of our qualified Mental Health Nurses followed by a one-off <strong>'Comprehensive assessment and report'</strong> with an allocated treating psychiatrist.
						  </p>	 */}
						</Card.Body>
					</Accordion.Collapse>
				</Card>				
				

				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="20">
					  <h3 className="gr-text-8">What other support services do you offer for Adult ADHD?</h3>					
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="20">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-6">
							Our allied health professionals - psychologists and mental health clinicians - have a special interest in Adult ADHD. 
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							To access Psychology Services - the Australian federal govermnment's Better Access initiative currently offers a rebate (up to 10 sessions within a 12-month period) with a valid <b>GP Mental Health Treatment Plan</b> and associated GP referral.
						  </p>	
						  <p className="gr-text-10 mb-7 mb-lg-4">
							Mental Health Clinicians & ADHD Coaches can be accessed through self-referral (i.e. you do not need a referral from a GP for these services). Simply contact us to arrange an initial appointment.
						  </p>							  
						</Card.Body>
					</Accordion.Collapse>
				</Card>	

				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="3">
					  <h3 className="gr-text-8">Do I need a referral?</h3>				
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="3">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							Yes, we require a valid referral - usually from your GP - in order to offer both our psychiatry and psychology services to you with Medicare rebates. Referrals to Psychology services must also be accompanied by a <b>GP Mental Health Treatment Plan</b> as part of the requirements of the Better Access initiative. 
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-4">
						   We accept referrals via email, fax (08 5117 3206) or secure messaging ('akkadian') addressed to 'Akkadian Health' <i>only</i>. We will not accept referrals made out to other clinicians or clinics / service providers.
						  </p>	
						  <p className="gr-text-10 mb-7 mb-lg-4">
						   Once we receive and process your referral, we will contact you within 1-2 weeks with instructions on how to book an appointment or to let you know that you have been placed on our waitlist for an appointment at a later date. Either way – we will contact you to let you know.
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-6">
							PLEASE NOTE - we do not accept requests for URGENT assessments and you cannot book an appointment unless we have processed your referral.
						  </p>							  
						</Card.Body>
					</Accordion.Collapse>
				</Card>	

				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="4">
					  <h3 className="gr-text-8">Are you accepting new patients?</h3>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="4">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-6">
							Yes, whilst wait times may vary (see below) we are always accepting referrals for new patients.
						  </p>
						</Card.Body>
					</Accordion.Collapse>
				</Card>	

				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="5">
					  <h3 className="gr-text-8">Do you see patients from metropolitan/city areas?</h3>					
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="5">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							Yes.
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-6">
							We may prioritise regional/rural patients, but we will see patients from anywhere across Australia.
						  </p>	
						</Card.Body>
					</Accordion.Collapse>
				</Card>	

				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="6">
					  <h3 className="gr-text-8">What are your wait times?</h3>					
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="6">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							Wait times for all services (including adult ADHD assessments) vary from 2-6 weeks depending on demand. We are constantly working to reduce these wait times by expanding our services to meet the increasing demand.
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-6">
							PLEASE NOTE - we do not accept requests for URGENT assessments.
						  </p>	
						</Card.Body>
					</Accordion.Collapse>
				</Card>	

				<Card id={"fees"}>
					<Accordion.Toggle as={Card.Header} eventKey="7">
					  <h3 className="gr-text-8">What are your fees?</h3>					
					</Accordion.Toggle>
					
					<Accordion.Collapse eventKey="7">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							We are a private service and therefore fees do apply for most services.
						  </p>				  
						  <p className="gr-text-10 mb-7 mb-lg-6">
							In many cases (particularly for psychiatry), fees are part of the clinical decision process based on the service provided and number of consultations required. The information here is a guide to our fees and associated Medicare rebates. 
						  </p>
						  
						  				  
						  <h6>ADHD Pathway:</h6>
						  
						  <ul>
						  <li><strong>$1280 </strong>upfront (an 8.5% discount) which covers both services.
						  Alternatively, pay for each service with 2 equal payments of <strong> $700</strong> (total: $1400). In almost all cases you will receive a $444.90 rebate from Medicare after your second service (Psychiatry). </li>
						  <p></p>
						   <li className="list-unstyled gr-text-12 mb-7 mb-lg-4">
						   <ul>								  
						   <li><strong>Service and Appointment 1: Intake consultation service with Akkadian's mental health nurse</strong> </li>
						   <li><strong>Service and Appointment 2: Comprehensive assessment and treatment plan provided by a psychiatrist.</strong> </li>
						   </ul>
						   </li>
						  <p></p>
						  <p className="gr-text-12 mb-7 mb-lg-4">NOTE: The private fee must be paid in full before you secure a booking for your appointment. Medicare rebates apply for the second service (Psychiatry) only.</p>
						  </ul>
						  
						  
						  				  
						  <h6>General Psychiatry services:</h6>
						  
						  <ul>								  
						  <li><strong>Initial consultation / assessment (45min+, new patient)</strong> - $720 (Rebate: up to $444.90)</li>
						  <li><strong>Standard consultation or Review (30min, existing patient)</strong> - $560 (Rebate: up to $278.15)</li>
						  <li><strong>Long consultation or Review (45min+, existing patient)</strong> - $660 (Rebate: up to $444.90)</li>
						  </ul>
						  
						  
						  
						  <h6>Psychology services:</h6>
						  
						  <ul>
						  <li><strong>Standard consultation</strong> - $220 (Rebate: $92.90)</li>
						  </ul>
						    
						  
						  
						  <h6>ADHD Coaching services:</h6>
						  <ul>
						  <li><strong>Standard consultation</strong> - $129 (Rebate:$0)</li>
						  </ul>
						  
						  <Divider />
						  <p className="gr-text-12 mt-9 mb-7 mb-lg-4">
						  If you are not registered for Medicare in Australia, you will not be eligible for the listed rebate.
						  </p>
						  <p className="gr-text-12 mb-7 mb-lg-4">
						  Medicare rebates <em>may</em> be higher depending on your personal concession status, Medicare threshold level and registered address. 
						  </p>						  
						  <p className="gr-text-12 mb-7 mb-lg-4">
						  Medicare rebates can only be claimed on your behalf after you have paid in full and after you have completed your appointment. Please allow 2-3 business days for your rebate to be processed by Medicare. 
						  </p>
						  <p className="gr-text-12 mb-7 mb-lg-4">
						  Please contact us to discuss fees for NDIS services (psychology only) or if you are a Gold Card Department of Veteran Affairs (DVA) holder.
						  </p>	
						</Card.Body>
					</Accordion.Collapse>
					
				</Card>						
				
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="8">
					  <h3 className="gr-text-8">How and when do I pay?</h3>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="8">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							Part of the appointment online booking process includes the registration of your Credit Card details. You authorise us to charge your Credit Card <i>after</i> your appointment - unless you have pre-paid for your services under one of our CARE PACKAGES, in which case there are no further incurred expenses. 
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							Where applicable, Payment is due (charged to your supplied Credit Card) immediately after your consultation. This may occur on the same day of your appointment or anytime within a few days. Please make sure that sufficient funds are available on your Credit Card to avoid additional administration charges or a disruption to your services. Follow-up appointments will not be offered until all outstanding invoices are settled.
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-6">
							In the event of a Credit Card error or Insufficient Funds - you will receive an invoice which allows you to pay for your consultation online. This is due immediately. Failure to pay may incur additional administration charges and result in a disruption to your services.
						  </p>
						</Card.Body>
					</Accordion.Collapse>
				</Card>	
				
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="9">
					  <h3 className="gr-text-8">How secure are my Credit Card details?</h3>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="9">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							Your payment details (Credit Card) are stored in a payment gateway operated by <strong>Pin Payments Australia</strong>, with stringent data and security protocols for storing payment details. Once initially entered and captured, they are not visible to anybody within our practice (neither practitioners nor admin staff) nor accessible by anyone who has access to our system software – not even for development or maintenance purposes. 
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-6">
							If payment details need to be altered or updated, they must be completely re-entered as a further security measure, as payment details cannot be edited. You can contact us to update your Credit Card details or request a link to do so securely.
						  </p>		
						</Card.Body>
					</Accordion.Collapse>
				</Card>	
				
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="10">
						<h3 className="gr-text-8">Do you have my referral?</h3>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="10">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							All referrals must be screened and processsed. We will notify you of the acceptance (or otherwise) of your referral within 1-2 weeks of receiving it. 
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-6"> 
						  Occasionally, referrals are lost in the 'mail' / 'fax transmission' or are never sent to us by GP clinics in the first place. If you have not heard from us within the referral processing time, please send us an email enquiry (with your name and the exact date that the referral was sent to us).
						  </p>	
						</Card.Body>
					</Accordion.Collapse>
				</Card>				

				
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="12">
						<h3 className="gr-text-8">What if I need medication?</h3>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="12">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							If medication is suggested as part of a treatment plan, your treating psychiatrist will instruct your referring GP – as your primary carer – to prescribe any standard medication.  
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							For ‘stimulant’ medication* that may be required for the treatment of ADHD, we will work closely with your referring GP - under your comprehensive treatment plan - to ensure that: 
						  </p>
						  <ul>
						  <li>your referring GP prescribes your initial medication,</li>
						  <li>you are stabilised on the recommended medication by your referring GP (with any concerns addressed by Akkadian Health),</li>
						  <li>your longer-term ongoing prescriptions are managed by your referring GP with the necessary 'state/territory-based' health authority approvals in place (supported by Akkadian Health).</li>
						  </ul>	
						  <p className="gr-text-10 mb-7 mb-lg-4">
							For patients located in South Australia, Akkadian Health has established the <strong>Akkadian Health <Link to="/adhd-care-centre-adelaide">Care Centre</Link> (SA)</strong> staffed by expert clinicians who will prescribe, initiate and monitor 'stimulant' medication for the treatment of ADHD under the guidance of Akkadian Health psychiatrists for patients diagnosed by Akkadian Health. The <Link to="/adhd-care-centre-adelaide">Care Centre</Link> operates on Franklin Street in the Adelaide CBD for convenient face-to-face consultations. If we have not reached out to you to arrange your appointments and you require this service, please contact the Akkadian Health Care Centre (SA) via email on care_sa@akkadianhealth.com OR on 1800 290 880 (option #5).
						  </p>
						  <p className="gr-text-12 mb-9 mb-lg-8">
							*‘Stimulant’ medications recommended in most cases for the treatment of ADHD are Schedule 8 drugs and are under the tightest restrictions for prescription across Australia. Unfortunately, Akkadian Health psychiatrists are not able to issue scripts for Schedule 8 drugs - this is the responsibility of your referring GP or our expert prescribing teams at one of our Akkadian Health Care Centres (currently available in SA only).
						  </p>
						  					  
						</Card.Body>
					</Accordion.Collapse>
				</Card>					

				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="13">
						<h3 className="gr-text-8">Can I cancel my appointment or request a refund?</h3>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="13">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							We understand that you might change your mind or that circumstances change and you may no longer require a booked appointment. Our policies are clear in relation to cancellations and requests for refunds (where a pre-payment has been made).  
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							You can cancel an appointment at any time. Please contact us in writing first via <a href="mailto:reception@akkadianhealth.com">reception@akkadianhealth.com</a> so that we have a written record of your cancellation request. However, cancellations made within 2 business days and failure to attend an appointment will incur the full cost of the appointment. 
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							Our <strong>Cancellation & Refund Policy</strong> is outlined in our <a href="https://akkadianhealth.com.au/terms-of-use">Terms of Use</a>, which requires 2 business days notice in the event of a cancellation. Refunds (for pre-payments) are issued under a similar schedule: 
						  </p>						  
						  
						  <ul>
						  <li>Cancellation more than 2 business days before your appointment - 70% refund;</li>
						  <li>Cancellation within 2 business days of your appointment - 0% i.e., No Refund;</li>
						  </ul>	
						  
						  <p className="gr-text-12 mb-9 mb-lg-8">
							NOTE: There are no refunds for health services received. The refund schedule reflects the cost of administration overheads for appointment management and re-bookings and covers situations (short-term cancellations and failure to attend) where we are unable to re-book the appointment slot. 
						  </p>						  					  
						</Card.Body>
					</Accordion.Collapse>
				</Card>	

				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="14">
						<h3 className="gr-text-8">What time is my 'scheduled appointment'?</h3>
					</Accordion.Toggle>

					<Accordion.Collapse eventKey="14">
						<Card.Body>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							Your appointment times are in your local time.  
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-4">
							If you booked your appointment via our online booking system, your confirmation email will state your booked appointment time in your local time/timezone (as indicated by you during the online booking process).
						  </p>
						  <p className="gr-text-10 mb-7 mb-lg-6">
							All appointment reminders are also in your local time/timezone. If you do not receive an appointment reminder either via email or SMS in the 24-48 hours prior to your appointment (this does happen from time to time) please contact us to confirm your appointment.
						  </p>		
						</Card.Body>
					</Accordion.Collapse>
				</Card>	
				
			</Accordion>

                </div>
              </Col>
            </Row>
          </Container>
        </div>
		
		
		
		
		
        <Divider />
        {/* <Testimonial page={"akkadian-about"} />
        <Team /> */}
      </PageWrapper>
    </>
  );
};
export default AboutPage;
