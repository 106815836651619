import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Alert } from 'reactstrap';



import Services from "../sections/akkadian/Services";
import Process from "../sections/akkadian/Process";
import Feature from "../sections/akkadian/Feature";
import TopAlert from "../sections/akkadian/Alert"

// Alternatives
/* import T_Services from "../sections/landing10/Services"; ////<--  services with images option - see landing11 too
import T_Hero2 from "../sections/landing6/Hero"; //<-- landing page option 
import S_Faq from "../sections/landing4/Faq";//<-- FAQ on home page option 
import T_FAQ from "../sections/landing3/Content2"; //<-- FAQ on home page option 
import BlogArticles from "../sections/landing2/News"; //<-- Blog articles on home page option
import Subscribe from "../sections/landing2/Newsletter"; //<-- CTA with email request on home page option - see landing 11 too (form)
import Pricing from "../sections/landing4/Pricing"; ////<-- Pricing component option - see landing6 & 8 too
import Parallax from "../sections/landing9/CTA"; ////<--  option for parallax image
import TopAlert from "../sections/landing10/Alert"; ////<--  option for catchy alert at top of home page !

import T_Content1 from "../sections/landing12/Content1";//<-- landing page option 
import T_Content2 from "../sections/landing12/Content2";//<-- landing page option 
import T_Content3 from "../sections/landing12/Content3";//<-- landing page option 

import T_Team from "../sections/landing12/Team";//<-- large team option 

import CTA from '../sections/akkadian/CTA';
import TextBlock from '../sections/akkadian/TextBlock';
import WhatWeDo from '../sections/akkadian/WhatWeDo';
import WhatMakesUsDifferent from '../sections/akkadian/WhatMakesUsDifferent';
import SignUpBlock from '../sections/akkadian/SignUpBlock'; */
// end Alternatives


import Hero from '../sections/akkadian/Hero';

//import BlogArticles from '../sections/akkadian/BlogArticles';
import Facts from '../sections/akkadian/Fact';

import Testimonial from '../sections/akkadian/Testimonial';

import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";

const TRACKING_ID = "G-40DZ4YTHXP"; // AKKADIAN GOOGLE GA-4 TRACKING ID

ReactGA.initialize(TRACKING_ID);

const IndexPage = () => {
    // const gContext = useContext(GlobalContext);

    // console.log(gContext);
    return (
        <>
            <PageWrapper
                headerConfig={{
                    theme: "light",
                    logo: "light",
					buttonText: "Contact us",
                }}
                footerConfig={{
                    theme: 'light',
                    style: "style2",
                }}
            >
                <Helmet>
                    <title>Akkadian Health | Telehealth Psychiatrists | ADHD Assessments Australia</title>
					<meta 
						name="description" 
						content="High quality, affordable psychiatrists and mental health professionals via secure telehealth video consultations across Australia. Specialising in ADHD Testing or Assessment and Treatment services." />
                </Helmet>
				
				<Hero />
                 {/* <TopAlert /> */}

{/*                  <Container>
                 <Alert color="light" style={{backgroundColor:'#F5F5F5', color:'black'}}>
				<h3 class="gr-text-7 px-6" style={{color:'red'}}>HOLIDAY CLOSURE NOTICE</h3>
				<hr />
				<p class="gr-text-12 px-12">We're taking a short end-of-year break. Our office (reception) will be <strong>closed between 21st DEC, 2024 and 5th JAN, 2025</strong> - returning on Monday 6th JAN 2025. </p>
				<p class="gr-text-12 px-12">Some of our clinicians will continue to practice during this period and booked appointments will proceed as scheduled.</p>
				<p class="gr-text-12 px-12">We wish you Seasons' Greetings and a Happy New Year!</p> 
				</Alert>       
                </Container> */}

{/*                  <Row>
                    <Col sm={12} md={4} lg={4} xl={4}>
                        <div className="py-12 py-lg-22 bg-white text-left pl-lg-12 pl-xl-24">

                            <Alert color="light" style={{backgroundColor:'#F5F5F5', color:'black'}}>	
                                <h3 className="gr-text-8 px-6 py-8 pt-lg-20" style={{color:'black'}}>LATEST NOTICES & UPDATES</h3>
								<p className="gr-text-14 px-6 py-3 text-right" style={{color:'grey'}}><strong>Last updated:</strong> 28th June 2024 </p>
                                <hr style={{borderColor:'black'}} />
                                <p className="gr-text-12 px-4" style={{color:'black'}}><strong>Need fast & affordable <a href="/services/adhd-testing" className={`gr-text-12`}>ADHD Testing, Assessment and Diagnosis</a>?</strong></p>						
                                <p className="gr-text-12 px-6" style={{color:'black'}}>We now accept referrals for telehealth <b>Adult ADHD Testing, Assessment and Diagnosis</b> via 
								email or ask your GP to send your referral directly to us. See <strong><a href="/for-gps" className={`gr-text-12`}>How to Refer</a></strong>.</p>				
                                <hr style={{borderColor:'black'}} />
                                <p className="gr-text-12 px-4" style={{color:'black'}}><strong>Looking for <a href="/services/allied-health" className={`gr-text-12`}>ADHD Treatment</a> services?</strong></p>
                                <p className="gr-text-12 px-6" style={{color:'black'}}>Our 'first' <strong><a href="/adhd-care-centre-adelaide" className={`gr-text-12`}>ADHD Care Centre (SA)</a></strong> in the Adelaide CBD is staffed by highly trained and experienced GPs and Nurse Practitioners (with priority access to our treating Psychiatrists). The clinic manages treatment plans - including prescription of medication for patients diagnosed with ADHD in South Australia.</p>
                                <hr style={{borderColor:'black'}} />
                                <p className="gr-text-12 px-4" style={{color:'black'}}><strong>What are our current wait times?</strong></p>
                                <p className="gr-text-12 px-6" style={{color:'black'}}>Wait times for all services vary. ADHD Assessment wait times are currently 2-3 weeks. </p>
                                <hr style={{borderColor:'black'}} />								
                            </Alert>
                        </div>
                    </Col>
                    <Col sm={12} md={8} lg={8} xl={8} className="pr-lg-10 pr-xl-22">
                        <Services id={"services"} subtitle={"Services you can count on"}/>
                    </Col>
                </Row> */}


                <Container>
                <Row>
                    <Services id={"services"} subtitle={"Services you can count on"}/>
                </Row>
                </Container>

				<Process id={"process"} />
				<Feature id={"why-us"} />
				<Facts id={"facts"} />


                {/*<Team isShowingCTA={true} id={"team"} />*/}
                <Testimonial id={"testimonials"} />
				
                {/* <BlogArticles s_id={"blog"} /> */}
				
				<div className="px-10 pb-5 text-center bg-light">
				<Alert color="warning">
				<h3 className="gr-text-9 px-3">PLEASE NOTE THAT WE ARE NOT A CRISIS SERVICE.</h3>
				<hr />
				<p className="gr-text-11 px-6">Crisis assessment and support teams are available 24 hours a day across Australia. Visit <a href="https://www.healthdirect.gov.au/crisis-management"> healthdirect/crisis-management</a> for details or call Lifeline on <a href="tel:131114"><strong>13 11 14</strong></a>.
				</p>
				<p className="gr-text-11 px-6">For emergencies, dial <a href="tel:000"><strong>000</strong></a> or attend your nearest hospital emergency department (ED). 
				</p>
				<p className="gr-text-11 px-6">
				Our 'private' services are not appropriate for acute incidents and urgent support.</p> 
				</Alert>
				</div>
				
				<div id={"contact"}>
				</div>
                
            </PageWrapper>
        </>
    );
};
export default IndexPage;
